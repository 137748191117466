(function($) {
	//NAVBAR CONTROL
	/*
	Event Type           Description
	show.bs.collapse     This event fires immediately when the show instance method is called.
	shown.bs.collapse    This event is fired when a collapse element has been made visible to     the user (will wait for CSS transitions to complete).
	hide.bs.collapse     This event is fired immediately when the hide method has been called. 
	hidden.bs.collapse   This event is fired when a collapse element has been hidden from the user (will wait for CSS transitions to complete).
	*/
 

	$('#menu_mobile').on('show.bs.collapse', function (e) {

		//e.preventDefault();
		//Hide menu
		var mc = $(this);
		mc.removeClass('animated fast fadeOut fadeIn');
		mc.addClass('animated fast fadeIn');

		$('.still_on_view').animate({opacity: 0},350);
		
		$('button.navbar-toggle').addClass('open');

		//No scroll
		$('body').addClass('stop-scrolling');

		//Stop all video loops if playing
		if(typeof videLoop !== 'undefined'){
			videoLoop.pause();
		}

	});

	$('#menu_mobile').on('hide.bs.collapse', function (e) {

		//e.preventDefault();

		//Hide menu
		var mc = $(this);
		mc.removeClass('animated fast fadeIn fadeOut');
		mc.addClass('animated fast fadeOut');

		$('.still_on_view').animate({opacity: 1},350);
		
		//Scroll
		$('body').removeClass('stop-scrolling');

		//animate hamb
		$('button.navbar-toggle').removeClass('open');

		//Resumen vídeos if the´re any
		if(typeof videLoop !== 'undefined'){
	  		videoLoop.play();
		}

	});  

	$('#menu_mobile .underlay, #menu_mobile .close').on('click',function(){
		//alert('click');
		$(".navbar-toggle").trigger( "click" );
	});

	//Oculat todo al hacer click entre secciones
	/*$('#menu_mobile li, #menu_ppal li').on('click',function(){
		//Si no es banana-things que se abre en nueva
		if($(this).attr("id")!=="menu-item-521"){
			$("body").addClass('inactive');
		}
	});*/


	//https://medium.com/@mariusc23/hide-header-on-scroll-down-show-on-scroll-up-67bbaae9a78c#.tbtgardz8
	/*
	 Hide Header on on scroll down
	*/
	
		var didScroll;
		var lastScrollTop = 0;
		var delta = 5;
		var navbarHeight = $('#header').outerHeight();
		
		$(window).scroll(function(event){
		    didScroll = true;
		});

		function hasScrolled() {
			//window.console.log('scroll');
		    var st = $(this).scrollTop();
		    
		    // Make sure they scroll more than delta
		    if(Math.abs(lastScrollTop - st) <= delta){
		        return;
		    }
		    
		    // If they scrolled down and are past the navbar, add class .nav-up.
		    // This is necessary so you never see what is "behind" the navbar.
		    if (st > lastScrollTop && st > navbarHeight ){
		        // Scroll UP
		        if(!$('#header').hasClass('nav-up')){
		        	$('#header, .filters').removeClass('nav-down').addClass('nav-up');
		        	//Si esta desplegado escondelo
		        	if($(window).width()<768){
			           setTimeout(function(){
				          //if($('.filters ul').is(":visible"))
				           		 $('.filters ul').fadeOut();
				        },150);
			       }
		        }
		    } else if(!$('#header').hasClass('nav-down')){
		        // Scroll DOWN
		        if(!$('#header').hasClass('nav-down') && st + $(window).height() < $(document).height()) {
		            $('#header, .filters').removeClass('nav-up').addClass('nav-down');
		            if($(window).width()>=768){
		           		 $('.filters ul').fadeIn();
		           	}
		        }
		    }
		    
		    lastScrollTop = st;
		}

		setInterval(function() {
		    //if (didScroll && $(window).width()>768) {//solo actua en resoluciones grandes
		        hasScrolled(); 
		        didScroll = false;
		    //}            
		}, 350);
	
	/*
	SUBMENU CASE STUDY!!
	Si existe el submenu estamos en case study: 
	Tenemos que marcar el menu cuando estamos en esa parte
	*/

	if($("#submenu").length>0){
		var submenu = $("#submenu");
		//Por ahora no funciona
		var elements = submenu.find("li");

		//center submenu vertical
		submenu.css({
			"margin-top":-(submenu.height()/2)
		});

		//Muestra - oculta submenu arriba del todo y abajo
		var checkSubmenu = function() {
			var offset = 500;
			var limite_arriba = $(window).scrollTop() <= $("#overview").position().top/2;//mostrara cuando llegamos a el parrafo ppal .destacado
			var limite_abajo = false;//$(window).scrollTop() + $(window).height() >= $(document).height() - offset;
			//console.log($(".overview").position().top + ' - ' + $(window).scrollTop())

		   	if( limite_arriba ||  limite_abajo) {
		   	    submenu.addClass('inactive');//stop().animate({opacity:0},350);
		   	}else{
		   	    submenu.removeClass('inactive');//submenu.stop().animate({opacity:1},350);
		   	}
		};

		//less expensive than scroll event
		setInterval(checkSubmenu, 500);

		/*
		Marca seccion activa del submenu
		*/
		var hide_sub ;
		if($(window).width()>768){

			//Oculta submenu al parar el scroll
			$(window).scroll(function(){
				
			    $('#submenu').css({opacity: 1});
			    //Oculta el menu cuando pasen 2 segundos
			    clearTimeout(hide_sub);
			    hide_sub = setTimeout(function(){
			    	 $('#submenu').css({opacity: 0 });
			    },4000);

			});

			//Muestralo en hover
			$('#submenu').hover(function(){
			    clearTimeout(hide_sub);
			},function(){
				hide_sub = setTimeout(function(){
			    	 $('#submenu').css({opacity: 0 });
			    },3000);
			});

			//Marca elementos del submenu
			var onScroll = function(event){
			    var scrollPos = $(document).scrollTop();
			    //MUETSRA OCULTA SUBMENU AL HACER SCROLL
			    
			    $('#submenu a').each(function () {
			        var currLink = $(this);
			        var scrollto = currLink.attr("href");
			       // NO marque el bottom top-top
			       if(scrollto.indexOf('#top-scroll')<0){
				        var refElement = $(scrollto);
				        //Desmarca
				        var top = refElement.position().top <= scrollPos + $(window).height()/2;
				        var bottom = refElement.position().top > scrollPos - refElement.parent().height();
				        

				        if (top && bottom && typeof currLink!=='undefined') { 
				            $('#menu-center ul li').removeClass("active");
				            $('#submenu li').removeClass("last");
				            currLink.parent().addClass("active last");
				            //location.hash = currLink.attr('href');
				            //return false; // stop iteration to mark just last elementenu

				        }
				        else{ 
				            currLink.parent().removeClass("active");
				        }
				   }
			    });

			    
			};

			setInterval(onScroll, 250);
		}
		 
	}

	/*
	FILTERS : categories, works ...
	*/

  
		var openCloseFilter = function(){
			var mc = $('.filters ul');
			$('.filter-button img').toggleClass('active');
			mc.toggle(function(){
				//$(this).addClass('active');
			});
		};

		$('.filter-button').click(openCloseFilter);

		//Mobile menu filtering - button click show hidden menu

		// filter items on button click for works
		$('.work .filters').find('li a').on("click touchstart",function(e) {

			//Filtra y anima items
			var filterValue = $(this).attr('data-filter');
			//alert(filterValue);
 
			//Oculta galeria
			$('.gallery').css({opacity:0});

			//Prepara galería x filtro y muestra
			setTimeout(function(){
				console.log("Filter content");
				$('.gallery').isotope({ filter: filterValue });
				$('.gallery').isotope();//refresh istotope layout 
				$('.gallery').css({opacity:1});
			},600);
			


	        //Marca boton menu filtros
			$('.filters li a').removeClass('selected');
			$(this).addClass('selected');

			//Siu estamos en res movil oculta el menu
			if($(window).width()<768){
				$('.filters ul').hide();
				$('.filter-button img').toggleClass('active');
			} 
	

			//Animate wow instances that are visible
			//wow.scrolled = true;
	    	//wow.scrollCallback();

			e.preventDefault();
			
		}); 

		//If we are in ARCHIVE veiewing all categories and thes no .current-cat
		if($(".current-cat").length===0 && $(".filters").length>0){
			$(".filters").find(".all").addClass('current-cat');//marca ALL
		}

		//Solucionar : Al picar Things se abre en nueva ventana pero se queda hovered y noo debería
		//alert(isMobileBrowser);
		$('li#menu-item-521 a').on('click' , function(e){
			//si la pantalla es tactil dejalo gris
			if(isMobileBrowser){
				//alert('click');
				$(this).css({'color':'hsla(0,0%,77%,.9)'});//force grey
			}
			/*e.preventDefault();
			var win = window.open($(this).attr('href') , '_blank');
			if (win) {
				//alert('open');
			    //Browser has allowed it to be opened
			    //win.focus();
			    //$(this).blur();//location.reload();//$(this).css({'color':'hsla(0,0%,77%,.9)'});//force grey
			} else {
			    //Browser has blocked it
			    alert('Please allow popups for this website');
			}*/
		});

})(jQuery); // Fully reference jQuery after this point. 
 