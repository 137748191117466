var wow;
var isMobileResolution = false;
var isMobileBrowser = false;
var $grid;


(function ($) {
  jQuery.easing.def = "easeInOutQuad";

  //Si la anchura es menor de 768px estamos en movil/tablet
  function checkMobile() {
    isMobileResolution = $(window).width() <= 768;
  }

  $(window).on("resize", checkMobile);
  checkMobile();
  // device detection
  if (
    /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(
      navigator.userAgent
    ) ||
    /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
      navigator.userAgent.substr(0, 4)
    )
  ) {
    isMobileBrowser = true;
  }

  //V-ALIGN :: centra textos la vertical cuando las imagenes sobre las que van el texto se hayan cargado - asi aseguramos saber la altura
  var verticalizables = $(".v-align");
  $(window).load(function () {
    if (verticalizables.length > 0) {
      verticalizables.verticalAlign();
    }
  });

  $(".modal").on("shown.bs.modal", function (event) {
    setTimeout(function () {
      verticalizables.verticalAlign();
    }, 350);
  });

  //Modal background backdrop
  $(".modal").on("hide.bs.modal", function (event) {
    //on hide remove class that only shown backdrop on certain modals
    $(".modal-backdrop").removeClass("show");
  });

  /*
    MAtch height of elements  with class .match-height
    */
  setTimeout(function () {
    $(".match-height").matchHeight();
    verticalizables.verticalAlign();
  }, 350);

  /*
    Alex magic grid magic > Para textos que van encima de imágenes quitamos la mitad de la altura con margin-top
    */
  function magicGrid_init() {
    //When reaching sm resolutions remove the overlay
    $(".text-overlay.ml,.text-overlay.mr").css(
      "margin-top",
      -$(".text-overlay.ml").height() / 2
    );
    //v-align
    if (verticalizables.length > 0) {
      verticalizables.verticalAlign();
    }
  }

  $(window).on("resize", magicGrid_init);
  magicGrid_init();

  /*
    Activate reveal ANIMATIONS > para animar las imágenes
    */

  wow = new WOW({
    boxClass: "wow", // animated element css class (default is wow)
    animateClass: "animated", // animation css class (default is animated)
    offset: 0, // distance to the element when triggering the animation (default is 0)
    mobile: true, // trigger animations on mobile devices (default is true)
    live: false, // act on asynchronously loaded content (default is true)
    callback: function (box) {
      // the callback is fired every time an animation is started
      // the argument that is passed in is the DOM node being animated
      //console.log("WOWWED : " + box.tagName);
    },
    scrollContainer: null, // optional scroll container selector, otherwise use window
  });

  wow.init();

  //force animation wihtout scrolling by chaging DOM
  // setTimeout(function() {
  //   wow.scrolled = true;
  //   wow.scrollCallback();
  // }, 1000);

  /*
    Social Networks on Journal Posts
    */
  var social_post = $(".single-post .share-post");
  if (social_post.length > 0) {
    var distance = $("#post-header").offset().top + $("#post-header").height(),
      $window = $(window);

    $window.scroll(function () {
      if ($window.scrollTop() >= distance) {
        // Your div has reached the top
        social_post.css({
          opacity: 1,
        });
      } else {
        social_post.css({
          opacity: 0,
        });
      }
    });
  }

  /*
    Isotope grid
    */

  //GRID Galerías

  //page-id-19 - works / page-id-15 - home

  //Are in home or works - were viewing normal gallery
  //$(".gallery").css({opacity:0});//fix first load unarranged bug

  $(window).load(function () {



    if ( 
      $(
        "body.page-template-page-services, body.page-template-taxonomy-work_category .gallery, body.tax-work_category .gallery, body.page-template-page-works .gallery , body.home, .page-template-page-journal-2 .gallery"
      ).length > 0
    ) {
      //WORKS + HOME
      //console.log("Load isotope");
      $grid = $(".gallery").isotope({
        itemSelector: ".item",
        percentPosition: true,
        transitionDuration: "0s",
        sortBy: "original-order",
        //layoutMode: "fitRows",
        horizontalOrder: true,
        masonry: {
          gutterWidth: 0, //Añadimos el gutter como padding el el .item
        },
        // disable scale transform transition when hiding
        hiddenStyle: {
          opacity: 0,
        },
        visibleStyle: {
          opacity: 1,
        },
      });

      // Reset layouts
      $grid.imagesLoaded().progress(function () {
        $grid.isotope("layout");
      });

      var runonce = true;
      $(window).scroll(function () {
        // if (
        //   $(window).scrollTop() + $(window).height() >=
        //   $(document).height() - 500
        // ) {
          if (runonce) {
            window.console.log("Reset layouts -------------------- ");
            $grid.isotope("layout");
            runonce = false;
            setTimeout(function(){ runonce = true; },10000);
          }
        //}
      });
    } else {
      //ABOUT
      $grid = $(".flexible-content .gallery").isotope({
        itemSelector: ".item",
        //layoutMode: "fitRows",
        horizontalOrder: true,
        getSortData: {
          number: ".ordering parseInt",
        },
        sortBy: "number",
        percentPosition: true,
        transitionDuration: "0s", //'0.8s'/*,º
        masonry: {
          gutterWidth: 0, //Añadimos el gutter como padding el el .item
          columnWidth: ".size_1_1", //Get columns size
        },
        // disable scale transform transition when hiding
        hiddenStyle: {
          opacity: 0,
          /*,
            					transform: 'scale(1)'*/
        },
        visibleStyle: {
          opacity: 1,
          /*,
            					transform: 'scale(1)'*/
        },
      });

      // Reset layouts
      $grid.imagesLoaded().progress(function () {
        window.console.log("RESET layout ----- ");
        $grid.isotope("layout");
      });

      //grid clients
      $grid = $("#clients .gallery").isotope({
        itemSelector: ".client",
       // layoutMode: "fitRows",
       horizontalOrder: true,
        sortBy: "original-order",
        masonry: {
          gutterWidth: 0, //Añadimos el gutter como padding el el .item
        },
      });

      $grid = $("#position-list .gallery").isotope({
        itemSelector: ".item",
        // layoutMode: "fitRows",
       horizontalOrder: true,
        sortBy: "original-order",
        masonry: {
          gutterWidth: 0, //Añadimos el gutter como padding el el .item
        },
      });

      //grid
      //grid CAMPUS Logos
      $grid = $("#campus-logos .gallery").isotope({
        itemSelector: ".item",
        // layoutMode: "fitRows",
       horizontalOrder: true,
        sortBy: "original-order",
        masonry: {
          gutterWidth: 0, //Añadimos el gutter como padding el el .item
        },
      });
    }
    setTimeout(function () {
      $(".gallery .match-height").matchHeight();
      $(".gallery").each(function () {
        var $container = $(this);
        $container.isotope("layout");
        setTimeout(function () {
          $container.isotope("layout");
        }, 1000);
      });
      $(".gallery").css({ opacity: 1 });
    }, 350);
  });

  // jQuery - Wait until images (and other resources) are loaded
  $(window).load(function () {
    // All images, css style sheets and external resources are loaded!
    // $('.gallery').isotope();//.layout();//refresh layout grid so sizes are right
  });

  /*
    Video Process
    */
  //alert('video?');

  if ($("video#video-keypoints").length > 0) {
    var video_keypoints = videojs(
      "video-keypoints",
      {
        controls: true,
        autoplay: false,
        preload: "auto",
      },
      function () {
        video_keypoints.pause();
      }
    );
    window.console.log("pause video keypoints");
    video_keypoints.on("play", function () {
      $("#video-keypoints .vjs-big-play-button").hide();
    });
    video_keypoints.on("pause", function () {
      $("#video-keypoints .vjs-big-play-button").show();
    });
    $(window).on("scroll", function () {
      if ($("#video-keypoints").inViewport()) {
        //video_keypoints.play();
      } else {
        video_keypoints.pause();
      }
    });
  }

  /*
    Video Reel - home / about ...
    */
  //alert('video?');
  if ($("#video").length > 0) {
    //Video loop basic home
    var videoLoop = videojs("video");

    videoLoop.ready(function () {
      //Hide big play button -  this is a looped background
      videoLoop.bigPlayButton.hide();
      //force autoplay on chrome online
      videoLoop.play();

      $(window).scroll(function () {
        if ($("#video").inViewport()) {
          videoLoop.play();
        } else {
          videoLoop.pause();
        }
      });
    });

    /* MUTE */
    $("#video")
      .parent()
      .click(function (e) {
        var isMuted = $(this).find("video").prop("muted");
        $(this).find("video").prop("muted", !isMuted);
        if (isMuted) {
          $(".mute-btn .on").show();
          $(".mute-btn .off").hide();
          // ga('gtag_UA_47198734_2.send', 'event', "Video", "Volume", "off"); //ga('send', 'event', 'Videos', 'play', 'Fall Campaign');
        } else {
          $(".mute-btn .on").hide();
          $(".mute-btn .off").show();
          //ga('gtag_UA_47198734_2.send', 'event', "Video", "Volume", "on"); //ga('send', 'event', 'Videos', 'play', 'Fall Campaign');
        }
      });

    //dependiendo estado video poner icono
    var isMuted = $("#video").find("video").prop("muted");
    if (isMuted) {
      $("#video").parent().find(".mute-btn .on").hide();
      $("#video").parent().find(".mute-btn .off").show();
    } else {
      $("#video").parent().find(".mute-btn .on").show();
      $("#video").parent().find(".mute-btn .off").hide();
    }

    $(".mute-btn").css({
      opacity: 0,
    });
    $("#video")
      .parent()
      .hover(
        function () {
          $(this).find(".mute-btn").css({
            opacity: 1,
          });
        },
        function () {
          $(this).parent().find(".mute-btn").css({
            opacity: 0,
          });
        }
      );

    /* PLAY - open modal and pause video loop */

    if ($(".video-wrapper .play").length > 0) {
      //Abrir modal onclick
      $(".video-wrapper")
        .click(function (e) {
          if (!isMobileResolution) {
            videoLoop.pause();
          }

          $("#video-modal").modal();
        })
        .css({
          cursor: "pointer",
        });

      //autoplay cuando se abra la modal
      $("#video-modal").on("show.bs.modal", function (event) {
        if (!isMobileResolution) {
          videoLoop.pause();
        }
      });

      $("#video-modal").on("hide.bs.modal", function (event) {
        if (!isMobileResolution) {
          videoLoop.play();
        }
        //Para el video de vimeo - deja timepo para la animacion
        setTimeout(function () {
          stopVideoIfr($("#video-modal .videoWrapper"));
        }, 1000);
      });
    }

    ////RESIZE - Positioning of play button between gap of txt and bottom of video canvas

    var resetVideoTexts = function () {
      var video = $(".video-wrapper");
      var play_btn = video.find(".play");
      var txt = video.find(".super-title ");

      //Centra el play entre txt y bottom
      var pos = Math.round(
        (video.height() / 2 - txt.height() / 2) / 2 - play_btn.height() / 2
      );
    };

    $(window).resize(resetVideoTexts);

    resetVideoTexts();

    //Si estamos en resoluciones menores de 768 no animes el vídeo reel al hacer scroll, que se vea directamente
    if (isMobileBrowser) {
      //$('.video-wrapper').removeClass('wow');
    }
  }

  /*
    Journal
    */

  //Click for whole item not just 'read more'
  $(".blog article")
    .click(function () {
      window.location = $(this).find("a.main-link").attr("href");
      return false;
    })
    .css({
      cursor: "pointer",
    });

  /*
    FLEXIBlE CONTROLL hACKS en single case-study
    */

  if ($(".single-case-study .flexible-content").length > 0) {
    var mc = $(".flexible-content");

    //.img-grid ... image or video
    //.img-responsive ... image

    //IMG FULL : Remove padding from previous-next images on full width images
    var img_full = mc.find(".full-width");
    var prev = img_full.parent().parent().parent().prev().find(".img-grid");
    if (typeof prev[0] !== "undefined") {
      $(prev[0]).css({
        "margin-bottom": 0,
      });
    }
    var next = img_full.parent().parent().parent().next().find(".img-grid");
    if (typeof next[0] !== "undefined") {
      $(next[0]).css({
        "margin-top": 0,
      });
    }

    //IMG 1-col : Remove margin from myself
    //var img_1col = mc.find(".1-col img").css({'margin-bottom':0,'margin-top':0});

    //TEXT : Remove padding from previous and next image if text - marign added by text
    var txt_grid = mc.find(".text");
    var img;
    prev = txt_grid
      .parent()
      .parent()
      .parent()
      .prev()
      .each(function ($i) {
        //Get last image of each set
        //img = $(this).find('.img-grid').last().css({'margin-bottom':0});
      });

    /*
      //console.log(prev[0]);
      if(typeof prev[0] !== 'undefined'){
      	//Get last image in set
      	alert(prev.length);
      	var prev_ele = prev[prev.length];
      	console.log(prev_ele);
      	prev_ele.css({'margin-bottom':0});
      }*/

    /*next = txt_grid.parent().parent().parent().next().find('.img-grid');
      if(typeof next[0] !== 'undefined'){
      	$(next[0]).css({'margin-top':0});
      }*/

    next = txt_grid
      .parent()
      .parent()
      .parent()
      .next()
      .each(function ($i) {
        //Get last image of each set
        //img = $(this).find('.imcg-grid').first().css({'margin-top':0});
      });

    //VIDEO : Remove padding from next prev image of vídeo
    //var video_grid = mc.find(".responsive-video");
    //video_grid.parent().parent().parent().prev().find('.img-responsive').css({'margin-bottom':0});
    //video_grid.parent().parent().parent().next().find('.img-responsive').css({'margin-top':0});

    //Cambia estructura de columnas si hay dos textos seguidos
    //$(window).on('resize', updateColumns);
    //updateColumns();
  }

  function updateColumns() {
    //768px

    if ($(window).width() >= 768 && $(window).width() < 1280) {
      mc.find(".row").each(function () {
        //txt + txt > 1 col
        //console.log(">"+$(this).attr('class'));
        //.row>div>.text
        if (!$(this).hasClass("row-title")) {
          // evita rows de titulo
          //console.log(">>"+$(this).attr('class'));
          var num_columnas = $(this).find("div").length;
          //console.log(num_columnas + " - num texts: " + $(this).find(".text").length);
          if ($(this).find(".text").length > 1) {
            console.log(
              "Esta fila tiene un .text : " + $(this).find(".text").length
            );
          }
        }

        //img + img > 2 col

        //img + txt > 1 col
      });
    }
  }

  /*
    NAVEGACION BOTONES EN ITEM GALLERY
    Posicion en el centro
    puntitos de navegación gallery - CARRERS
    */
  var dots = $("#position-list .gallery .dots");
  if (dots.length > 0) {
    var post_dots = function () {
      dots.css({
        "margin-left": -(dots.width() / 2),
      });
    };

    $(window).resize(post_dots);
    post_dots();
    //Cambia listado al clikar

    $("#position-list  .gallery .item").each(function () {
      var mc = $(this);
      var bloque_activo = 1;
      //CLick dots
      $(this)
        .find(".dots li")
        .click(function () {
          //Marca el bloque
          var target = $(this).not(".active").attr("data-target");
          mc.find(".block").removeClass("active");
          mc.find("." + target).addClass("active");
          //marca el dot - boton
          mc.find(".dots li").removeClass("active");
          $(this).addClass("active");
        });
    });
  }

  var position_btns = $("#position-list .button-title");
  if (position_btns.length > 0) {
    position_btns.click(function () {
      if (isMobileResolution) {
        $(this).find(".chevron").toggleClass("active");
        //$('.gallery').isotope();//.layout();
        var mc = $(this).parent().find(".positions");
        if (!mc.hasClass("activated")) {
          //Muestra
          mc.css({
            opacity: 0,
          });
          mc.css({
            display: "block",
          });
          mc.animate(
            {
              opacity: 1,
            },
            1000
          );
          $(".gallery").isotope();
        } else {
          //Oculta
          mc.animate(
            {
              opacity: 0,
            },
            350,
            function () {
              mc.css({
                display: "none",
              });

              $(".gallery").isotope();
            }
          );
        }
        mc.toggleClass("activated"); //active class has opacity:1

        /*mc.fadeToggle({duration:"0",complete:function(){
          	$(this).toggleClass('active');
          	$('.gallery').isotope();
          }});*/
      }
    });
  }

  //ITEM Animations on hover
  $(".gallery")
    .find(".item")
    .hover(
      function () {
        var mc = $(this);
        mc.find(".wrapper,.sideB").stop().velocity(
          {
            opacity: 1,
          },
          350
        );
        mc.find("img.original").stop().velocity(
          {
            opacity: 0,
          },
          350
        );
        setTimeout(function () {
          //mc.find('img.original').css({'display':'none'});
        }, 350);
        //$(this).find('img.original').addClass('wow animated fadeOut');
      },
      function () {
        if ($("#campus-logos").length > 0) {
          $(this).find(".wrapper").stop().velocity(
            {
              opacity: 1,
            },
            350
          );
          $(this).find(".sideB").stop().velocity(
            {
              opacity: 0,
            },
            350
          );
        } else {
          $(this).find(".wrapper").stop().velocity(
            {
              opacity: 0,
            },
            350
          );
        }
        $(this).find("img.original").stop().velocity(
          {
            opacity: 1,
          },
          350
        );
      }
    );

  //Item DOUBLE CLICK - Override click when on mobile devices - First vclick as hover, 2nd as true clicks
  var tapped = "";
  var gallery_buttons = $(
    "#recent-work .gallery, .works .gallery, #team .gallery, #campus-logos .gallery, .tax-work_client .gallery, #related-content .gallery"
  );

  //Solo para móviles ::: TAP
  gallery_buttons.find(".item").on("click", function (e) {
    //Solo si no es un Click desde un PC
    //alert(e.type);
    if (isMobileBrowser && $(this)[0] !== tapped[0]) {
      //alert('def prevented');
      e.preventDefault();
      var _tapped;
      //Pequeño hack para acomodar la estrcutura de #campus-logos
      if ($("#campus-logos").length > 0) {
        if (tapped !== "") {
          //Avoud flicker issue
          _tapped = tapped;
          setTimeout(function () {
            //oculta el anterior
            _tapped.find("img.original").css({
              opacity: 1,
            });
            //tapped.find('.wrapper').css({opacity:1});
            _tapped.find(".sideB").css({
              opacity: 0,
            });
          }, 350);
        }
        $(this).find(".sideB").css({
          opacity: 1,
        });
        $(this).find("img.original").css({
          opacity: 0,
        });
      } else {
        if (tapped !== "") {
          //Avoid flicker issue
          _tapped = tapped;
          setTimeout(function () {
            //oculta el anterior
            //_tapped.find('img.original').css({opacity:1});
            //_tapped.find('.wrapper').css({opacity:0});
          }, 500);
        }

        //$(this).find('.wrapper').css({opacity:1});
        //$(this).find('img.original').css({opacity:0});
      }
    }

    //e.preventDefault();
    tapped = $(this);
  });

  /*
    FOOTER
    */

  //Open modals from modal
  var hash = window.location.hash;
  if (hash.includes("modal")) {
    //Open modal
    if (hash === "#modal-terms") {
      $("#modal-terms").modal();
      $("#terms_block").show();
    } else if (hash === "#modal-privacy") {
      $("#modal-terms").modal();
      $("#privacy_block").show();
    }
  }

  //Newsletter
  $(
    "#menu-item-298 a,#menu-item-6682 a,#menu-item-6687 a,#menu-item-6692 a"
  ).click(function (e) {
    e.preventDefault();
    location.href = "#footer";
    $(".newsletter").slideToggle();
  });

  $(
    "#menu-item-299 a,#menu-item-6688 a,#menu-item-6683 a,#menu-item-6693 a,#cn-more-info,.privacy"
  ).click(function (e) {
    e.preventDefault();
    $("#modal-terms").modal();
    $("#privacy_block").show();
    $("#terms_block").hide();
  });

  $(
    "#menu-item-300 a,#menu-item-6684 a,#menu-item-6689 a,#menu-item-6694 a,.terms"
  ).click(function (e) {
    e.preventDefault();
    $("#modal-terms").modal();
    $("#privacy_block").hide();
    $("#terms_block").show();
  });

  /*
  GA EVENTS
  */

  //on mailto:newbusiness@studiobanana.com thorw GA event
  // $("a[href*='mailto:newbusiness@studiobanana.com']").click(function(e) {
  //   ga('gtag_UA_47198734_2.send', 'event', 'Click', 'Mail to', 'newbusiness@studiobanana.com');
  // });

  //On newsletetr sign up fire GA event
  /*mc4wp.forms.on('subscribed', function(form) {
  	// analytics.js
  	ga('gtag_UA_47198734_2.send', 'event', 'Forms', 'Sign-up', 'Newsletter');
  });*/

  //Cmabia filename en Careers
  $(".single-job input:file").change(function (e) {
    var fileName = e.target.files[0].name;
    $("#file").parent().parent().find("label>span").html(fileName);
  });

  //Validacion email antes de eviar el formulario
  function isValidEmail(email) {
    var re =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }

  function validateMail(e) {
    console.log("validate email");
    $("#result").text("");
    var input = $(this).find('input[type="email"]');
    var email = input.val();
    if (!isValidEmail(email)) {
      //console.log('not valid');
      e.preventDefault(); //stop sending forms
      var message =
        '<p class="help-block" style="opacity:1;">Invalid Email address</p>';
      $(".help-block").remove();
      $(this).find(".mc4wp-alert").html(message);
    } else {
      //Suscribe
      // ga('gtag_UA_47198734_2.send', 'event', 'Forms', 'Sign-up', 'Position at Careers');
    }
  }

  $("#mc4wp-form-1").on("submit", validateMail);

  /*
  SCROLL TO animado
  */

  $('a[href*="#"]:not([href="#"])').click(function (e) {
    if (
      location.pathname.replace(/^\//, "") ===
        this.pathname.replace(/^\//, "") &&
      location.hostname === this.hostname
    ) {
      var target = $(this.hash);
      target = target.length ? target : $("[name=" + this.hash.slice(1) + "]");
      if (target.length) {
        $("html, body").animate(
          {
            scrollTop: target.offset().top,
          },
          1000
        );
        return false;
      }
    }
  });

  /*
  SHARE CONTENT MODAL - open button
  */
  $(".share").on("click", function (e) {
    e.preventDefault();
    $("#modal-share").modal();
  });

  //activate clipboard to copy text to clipboard on click
  new Clipboard(".clipboard");

  /*
  CAMPUS
  Activate slick sliders
  */
  $(".slick-slider").slick({
    dots: true,
  });
  //Muestra oculta flechas
  $(".slick-slider .slick-arrow").css({
    opacity: 0,
  });
  $(".slick-slider").hover(
    function () {
      $(this).find(".slick-arrow").stop().animate(
        {
          opacity: 1,
        },
        600
      );
    },
    function () {
      $(this).find(".slick-arrow").stop().animate(
        {
          opacity: 0,
        },
        600
      );
    }
  );
  //Reset height again with slick sliders
  $(window).on("resize", function () {
    if ($(".slick-slider").length > 0) {
      setTimeout(function () {
        $(".match-height").matchHeight();
      }, 1000);
    }
  });
  /*
  Autoplay - pause Vimeo videos on viewport
  */

  function videoPause() {
    console.log("video pause >>");
    var elementToDetect = $('iframe[src*="vimeo.com"]');
    //var elementToDetect = $('iframe[src*="vimeo.com"]');
    elementToDetect.each(function (i) {
      console.log("Check vídeo Iframe " + i);
      var player = $f(this);

      //Tiene prop autoplay en source
      if ($(this).attr("src").split("autoplay=1").length > 1) {
        if (!$(this).inViewport()) {
          /*player.api('play');*/
          //console.log("      >>> Stop vimeo video");
          player.api("pause");
          // player.api('setVolume', 0);
          //console.log("    >>> pause vimeo video");
        } else {
          //EN viewport - Si tiene autoplay dale a play
          /*console.log($(this).attr("src"));
          console.log("length >> " + $(this).attr("src").split("autoplay").length);*/
          //console.log("    >>> play vimeo video - Tiene autoplay");
          player.api("play");
          //player.api('setVolume', 1);
        }
      }
    });
  }

  if (!isMobileBrowser || !isMobileResolution) {
    //Wait a few seconds for autoplay
    // videoPause();

    //setTimeout(videoPause, 2000);

    //Check for iframe loaded viudeos
    // $('iframe[src*="vimeo.com"]').load(function() {
    //   console.log('Iframe loaded - ' + $(this).attr("src"));
    // });

    $(window).load(function () {
      videoPause();
    });

    $(window).scroll(function (e) {
      videoPause();
    });

    $(window).focus(function () {
      videoPause();
    });
  }

  //Make all videos fluid unknown video ratio
  //Not used anymore
  function fluidVideos() {
    console.log("Fluid videos");
    $(".responsive-video").each(function () {
      var ratio = Math.round(
        100 *
          ($(this).find("iframe").attr("height") /
            $(this).find("iframe").attr("width"))
      );
      $(this).css({
        "padding-bottom": ratio + "%",
      });
      //console.log("Reset iframe ratio >> " + ratio + "- from w:" + $(this).find('iframe').width() + " - height: " + $(this).find('iframe').height());
    });
  }
  //fluidVideos();

  //Capabilities v2
  var cap_panel = $(".capabilities-panel.interactive");

  if (cap_panel.length > 0) {
    var titles = cap_panel.find(".capabilities-panel__title");
    titles
      .on("click", function () {
        var target = $(this).data("target");
        titles.removeClass("active");
        cap_panel.find("#title-" + target).addClass("active");
        cap_panel
          .find(".capabilities-panel__contents__item")
          .removeClass("active");
        cap_panel
          .find(
            ".capabilities-panel__contents__item[data-item='" + target + "']"
          )
          .addClass("active");
      })
      .css("cursor", "pointer");
  }

  var loadmoreClicks = 1;
  $('.btn-loadmore').click(function(e){
    e.preventDefault();
    //Show first 8 hidden items
    var total_num = $('.limit-xs-loadmore .item').length;
    var pages_num = Math.ceil(total_num / 8);

    $('.limit-xs-loadmore .item:hidden').slice(0,8).show();
    $grid.isotope("layout");
    //Force wow anim
    wow.scrolled = true;
    wow.scrollCallback();

    loadmoreClicks++;
    console.log(loadmoreClicks , pages_num);

    if(loadmoreClicks>=pages_num){
      $(this).hide();
      window.console.log("Hide loadmore button");
    }
  });


})(jQuery); // Fully reference jQuery after this point.

// SUPERGLOBALES

//conrtrol video playback for video keypoints
function gototimecode(secs) {
  var video = videojs("video-keypoints");
  video.pause();

  console.log("Video time: " + video.currentTime());
  jQuery("html, body").animate(
    {
      scrollTop: jQuery("#video-keypoints").offset().top - 80,
    },
    600,
    "easeOutQuint",
    function () {
      setTimeout(function () {
        video.currentTime(secs);
        video.play();
      }, 600);
    }
  );
}

function toggleVideo(state) {
  // if state == 'hide', hide. Else: show video
  var div = document.getElementById("popupVid");
  var iframe = div.getElementsByTagName("iframe")[0].contentWindow;
  div.style.display = state === "hide" ? "none" : "";
  func = state === "hide" ? "pauseVideo" : "playVideo";
  iframe.postMessage(
    '{"event":"command","func":"' + func + '","args":""}',
    "*"
  );
}

function animationHover(trigger, element, animation) {
  element = $(element);
  trigger = $(trigger);
  trigger.hover(
    function () {
      element.addClass("animated " + animation);
    },
    function () {
      //wait for animation to finish before removing classes
      window.setTimeout(function () {
        element.removeClass("animated " + animation);
      }, 2000);
    }
  );
}
